var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem)
    ? _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            {
              attrs: {
                title:
                  this.$route.query.put === "put" ? "添加开票结果" : "发票记录"
              }
            },
            [
              _c(
                "div",
                { staticClass: "partition-form" },
                [
                  _c(
                    "van-form",
                    { ref: "appForm" },
                    [
                      _c("van-field", {
                        attrs: {
                          label: "发票种类：",
                          placeholder: "请输入发票种类",
                          value: _vm.utils.statusFormat(
                            _vm.appForm.distinguish,
                            "Distinguish"
                          ),
                          "input-align": _vm.constants.input_align,
                          "label-width": _vm.constants.labelWidth
                        },
                        on: {
                          click: function($event) {
                            return _vm.fieldClick("sealTypeList")
                          }
                        }
                      }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "关联订单名称：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.orderId
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "关联订单名称：",
                              placeholder: "请输入关联订单名称",
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            on: {
                              click: function($event) {
                                return _vm.fieldClick("sealList")
                              }
                            },
                            model: {
                              value: _vm.appForm.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "orderId", $$v)
                              },
                              expression: "appForm.orderId"
                            }
                          }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单编号：",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.appForm.orderNo
                          )
                        }
                      }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票方类型：",
                              value: _vm.utils.statusFormat(
                                _vm.appForm.invoiceSendType,
                                "EnterpriseOrgType"
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              value: _vm.utils.statusFormat(
                                _vm.appForm.invoiceSendType,
                                "EnterpriseOrgType"
                              ),
                              label: "开票方类型：",
                              placeholder: "请输入开票方类型",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            on: {
                              click: function($event) {
                                return _vm.fieldClick("invoiceSendType")
                              }
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票方：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceSendName
                              )
                            }
                          })
                        : _vm.appForm.invoiceSendType == 7
                        ? _c("van-field", {
                            attrs: {
                              label: "开票方：",
                              placeholder: "请输入开票方",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceSendName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceSendName", $$v)
                              },
                              expression: "appForm.invoiceSendName"
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票方：",
                              placeholder: "请输入开票方",
                              value: _vm.appForm.invoiceSendName,
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            on: {
                              click: function($event) {
                                return _vm.fieldClick("invoiceSendId")
                              }
                            }
                          }),
                      _c("van-field", {
                        attrs: {
                          value: _vm.utils.statusFormat(
                            _vm.appForm.invoiceReceiveType,
                            "EnterpriseOrgType"
                          ),
                          label: "收票方类型：",
                          placeholder: "请输入收票方类型",
                          required: "",
                          rules: [{ required: true }],
                          "input-align": _vm.constants.input_align,
                          "label-width": _vm.constants.labelWidth
                        },
                        on: {
                          click: function($event) {
                            return _vm.fieldClick("invoiceReceiveType")
                          }
                        }
                      }),
                      this.$route.query.put === "put"
                        ? _c("van-field", {
                            attrs: {
                              label: "收票方：",
                              placeholder: "请输入收票方",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceReceiveName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceReceiveName", $$v)
                              },
                              expression: "appForm.invoiceReceiveName"
                            }
                          })
                        : _vm.appForm.invoiceReceiveType == 7
                        ? _c("van-field", {
                            attrs: {
                              label: "收票方：",
                              placeholder: "请输入开票方",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceReceiveName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceReceiveName", $$v)
                              },
                              expression: "appForm.invoiceReceiveName"
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "收票方：",
                              placeholder: "请输入收票方",
                              value: _vm.appForm.invoiceReceiveName,
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            on: {
                              click: function($event) {
                                return _vm.fieldClick("invoiceReceiveId")
                              }
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "发票类型：",
                              value: _vm.utils.statusFormat(
                                _vm.appForm.invoiceType,
                                "invoiceTypes"
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "发票类型：",
                              placeholder: "请输入发票类型",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            on: {
                              click: function($event) {
                                return _vm.fieldClick("invoiceTypes")
                              }
                            },
                            model: {
                              value: _vm.appForm.invoiceTypeLabel,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceTypeLabel", $$v)
                              },
                              expression: "appForm.invoiceTypeLabel"
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票品名：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.productName
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票品名：",
                              placeholder: "请输入开票品名",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "productName", $$v)
                              },
                              expression: "appForm.productName"
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票数量：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceMeal
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票数量：",
                              placeholder: "请输入开票数量",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceMeal,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceMeal", $$v)
                              },
                              expression: "appForm.invoiceMeal"
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票张数：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceNum
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票张数：",
                              placeholder: "请输入开票张数",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceNum,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceNum", $$v)
                              },
                              expression: "appForm.invoiceNum"
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票金额(合计: 元)：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceMoney
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票金额(合计: 元)：",
                              placeholder: "请输入开票金额",
                              required: "",
                              rules: [{ required: true }],
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.invoiceMoney,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceMoney", $$v)
                              },
                              expression: "appForm.invoiceMoney"
                            }
                          }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "开票时间：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.invoiceDate
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "开票时间：",
                              placeholder: "请输入开票时间",
                              required: "",
                              rules: [{ required: true }],
                              "label-width": _vm.constants.labelWidth,
                              "input-align": _vm.constants.input_align
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog.showDate = true
                              }
                            },
                            model: {
                              value: _vm.appForm.invoiceDate,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "invoiceDate", $$v)
                              },
                              expression: "appForm.invoiceDate"
                            }
                          }),
                      _c("van-calendar", {
                        attrs: {
                          "min-date": _vm.minDate,
                          "max-date": _vm.maxDate
                        },
                        on: { confirm: _vm.confirmDate },
                        model: {
                          value: _vm.dialog.showDate,
                          callback: function($$v) {
                            _vm.$set(_vm.dialog, "showDate", $$v)
                          },
                          expression: "dialog.showDate"
                        }
                      }),
                      this.$route.query.put === "put"
                        ? _c("van-cell", {
                            attrs: {
                              title: "备注：",
                              value: _vm.utils.isEffectiveCommon(
                                _vm.appForm.remark
                              )
                            }
                          })
                        : _c("van-field", {
                            attrs: {
                              label: "备注：",
                              placeholder: "请输入备注",
                              "input-align": _vm.constants.input_align,
                              "label-width": _vm.constants.labelWidth
                            },
                            model: {
                              value: _vm.appForm.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "remark", $$v)
                              },
                              expression: "appForm.remark"
                            }
                          }),
                      _c(
                        "van-cell",
                        { attrs: { title: "发票原件：" } },
                        [
                          _c("upload-file", {
                            attrs: {
                              "rectangle-style": "",
                              "upload-text": "发票原件",
                              query: _vm.query,
                              "before-read": _vm.beforeUpload
                            },
                            on: {
                              fileUploadSuccess: function(
                                response,
                                file,
                                fileList
                              ) {
                                return _vm.fileUploadSuccess(
                                  "fileDetailInfoVos",
                                  response,
                                  file,
                                  fileList
                                )
                              },
                              change: function(arr) {
                                return _vm.change("fileDetailInfoVos", arr)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("van-form", { ref: "auditForm" }, [
                        _c(
                          "div",
                          {
                            staticClass: "fixed-btn-bottom",
                            staticStyle: { "z-index": "2" }
                          },
                          [
                            _c(
                              "van-row",
                              { attrs: { gutter: "20" } },
                              [
                                _c(
                                  "van-col",
                                  { attrs: { span: "12" } },
                                  [
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          color: "#1373CC",
                                          block: "",
                                          type: "primary"
                                        },
                                        on: { click: _vm.handleSave }
                                      },
                                      [_vm._v(" 保存 ")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "van-col",
                                  { attrs: { span: "12" } },
                                  [
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          color: "#1373CC",
                                          block: "",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.back()
                                          }
                                        }
                                      },
                                      [_vm._v(" 返回 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { round: "", position: "bottom" },
                      model: {
                        value: _vm.dialog.selectDialog,
                        callback: function($$v) {
                          _vm.$set(_vm.dialog, "selectDialog", $$v)
                        },
                        expression: "dialog.selectDialog"
                      }
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.columns,
                          "value-key": _vm.valueKey
                        },
                        on: {
                          cancel: function($event) {
                            _vm.dialog.selectDialog = false
                          },
                          confirm: _vm.confirmPicker
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem)
                ? _c(
                    "van-tab",
                    { attrs: { title: "审批意见" } },
                    [
                      _c("workflow-audit", {
                        attrs: {
                          "task-id": _vm.$route.query.taskId,
                          "page-key": "ht_con_seal",
                          "business-key": _vm.appForm.sealId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          "view-mode": true
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "partition-form" },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 发票记录 ")])
          ]),
          _c(
            "van-form",
            { ref: "appForm" },
            [
              _c("van-field", {
                attrs: {
                  label: "发票种类：",
                  placeholder: "请输入发票种类",
                  value: _vm.utils.statusFormat(
                    _vm.appForm.distinguish,
                    "Distinguish"
                  ),
                  required: "",
                  rules: [{ required: true }],
                  "input-align": _vm.constants.input_align,
                  "label-width": _vm.constants.labelWidth
                },
                on: {
                  click: function($event) {
                    return _vm.fieldClick("sealTypeList")
                  }
                }
              }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "关联订单名称：",
                      value: _vm.utils.isEffectiveCommon(_vm.appForm.orderId)
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "关联订单名称：",
                      placeholder: "请输入关联订单名称",
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.fieldClick("sealList")
                      }
                    },
                    model: {
                      value: _vm.appForm.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "orderId", $$v)
                      },
                      expression: "appForm.orderId"
                    }
                  }),
              _c("van-cell", {
                attrs: {
                  title: "订单编号：",
                  value: _vm.utils.isEffectiveCommon(_vm.appForm.orderNo)
                }
              }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票方类型：",
                      value: _vm.utils.statusFormat(
                        _vm.appForm.invoiceSendType,
                        "EnterpriseOrgType"
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      value: _vm.utils.statusFormat(
                        _vm.appForm.invoiceSendType,
                        "EnterpriseOrgType"
                      ),
                      label: "开票方类型：",
                      placeholder: "请输入开票方类型",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.fieldClick("invoiceSendType")
                      }
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票方：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.appForm.invoiceSendName
                      )
                    }
                  })
                : _vm.appForm.invoiceSendType == 7
                ? _c("van-field", {
                    attrs: {
                      label: "开票方：",
                      placeholder: "请输入开票方",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceSendName,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceSendName", $$v)
                      },
                      expression: "appForm.invoiceSendName"
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票方：",
                      placeholder: "请输入开票方",
                      value: _vm.appForm.invoiceSendName,
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.fieldClick("invoiceSendId")
                      }
                    }
                  }),
              _c("van-field", {
                attrs: {
                  value: _vm.utils.statusFormat(
                    _vm.appForm.invoiceReceiveType,
                    "EnterpriseOrgType"
                  ),
                  label: "收票方类型：",
                  placeholder: "请输入收票方类型",
                  required: "",
                  rules: [{ required: true }],
                  "input-align": _vm.constants.input_align,
                  "label-width": _vm.constants.labelWidth
                },
                on: {
                  click: function($event) {
                    return _vm.fieldClick("invoiceReceiveType")
                  }
                }
              }),
              this.$route.query.put === "put"
                ? _c("van-field", {
                    attrs: {
                      label: "收票方：",
                      placeholder: "请输入收票方",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceReceiveName,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceReceiveName", $$v)
                      },
                      expression: "appForm.invoiceReceiveName"
                    }
                  })
                : _vm.appForm.invoiceReceiveType == 7
                ? _c("van-field", {
                    attrs: {
                      label: "收票方：",
                      placeholder: "请输入开票方",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceReceiveName,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceReceiveName", $$v)
                      },
                      expression: "appForm.invoiceReceiveName"
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "收票方：",
                      placeholder: "请输入收票方",
                      value: _vm.appForm.invoiceReceiveName,
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.fieldClick("invoiceReceiveId")
                      }
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "发票类型：",
                      value: _vm.utils.statusFormat(
                        _vm.appForm.invoiceType,
                        "invoiceTypes"
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "发票类型：",
                      placeholder: "请输入发票类型",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    on: {
                      click: function($event) {
                        return _vm.fieldClick("invoiceTypes")
                      }
                    },
                    model: {
                      value: _vm.appForm.invoiceTypeLabel,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceTypeLabel", $$v)
                      },
                      expression: "appForm.invoiceTypeLabel"
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票品名：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.appForm.productName
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票品名：",
                      placeholder: "请输入开票品名",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "productName", $$v)
                      },
                      expression: "appForm.productName"
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票数量：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.appForm.invoiceMeal
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票数量：",
                      placeholder: "请输入开票数量",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceMeal,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceMeal", $$v)
                      },
                      expression: "appForm.invoiceMeal"
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票张数：",
                      value: _vm.utils.isEffectiveCommon(_vm.appForm.invoiceNum)
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票张数：",
                      placeholder: "请输入开票张数",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceNum,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceNum", $$v)
                      },
                      expression: "appForm.invoiceNum"
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票金额(合计: 元)：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.appForm.invoiceMoney
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票金额(合计: 元)：",
                      placeholder: "请输入开票金额",
                      required: "",
                      rules: [{ required: true }],
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.invoiceMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceMoney", $$v)
                      },
                      expression: "appForm.invoiceMoney"
                    }
                  }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "开票时间：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.appForm.invoiceDate
                      )
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "开票时间：",
                      placeholder: "请输入开票时间",
                      required: "",
                      rules: [{ required: true }],
                      "label-width": _vm.constants.labelWidth,
                      "input-align": _vm.constants.input_align
                    },
                    on: {
                      click: function($event) {
                        _vm.dialog.showDate = true
                      }
                    },
                    model: {
                      value: _vm.appForm.invoiceDate,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "invoiceDate", $$v)
                      },
                      expression: "appForm.invoiceDate"
                    }
                  }),
              _c("van-calendar", {
                attrs: { "min-date": _vm.minDate, "max-date": _vm.maxDate },
                on: { confirm: _vm.confirmDate },
                model: {
                  value: _vm.dialog.showDate,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog, "showDate", $$v)
                  },
                  expression: "dialog.showDate"
                }
              }),
              this.$route.query.put === "put"
                ? _c("van-cell", {
                    attrs: {
                      title: "备注：",
                      value: _vm.utils.isEffectiveCommon(_vm.appForm.remark)
                    }
                  })
                : _c("van-field", {
                    attrs: {
                      label: "备注：",
                      placeholder: "请输入备注",
                      "input-align": _vm.constants.input_align,
                      "label-width": _vm.constants.labelWidth
                    },
                    model: {
                      value: _vm.appForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "remark", $$v)
                      },
                      expression: "appForm.remark"
                    }
                  }),
              _c(
                "van-cell",
                { attrs: { title: "发票原件：" } },
                [
                  _c("upload-file", {
                    attrs: {
                      "rectangle-style": "",
                      "upload-text": "发票原件",
                      query: _vm.query,
                      "before-read": _vm.beforeUpload
                    },
                    on: {
                      fileUploadSuccess: function(response, file, fileList) {
                        return _vm.fileUploadSuccess(
                          "fileDetailInfoVos",
                          response,
                          file,
                          fileList
                        )
                      },
                      change: function(arr) {
                        return _vm.change("fileDetailInfoVos", arr)
                      }
                    }
                  })
                ],
                1
              ),
              _c("van-form", { ref: "auditForm" }, [
                _c(
                  "div",
                  {
                    staticClass: "fixed-btn-bottom",
                    staticStyle: { "z-index": "2" }
                  },
                  [
                    _c(
                      "van-row",
                      { attrs: { gutter: "20" } },
                      [
                        _c(
                          "van-col",
                          { attrs: { span: "12" } },
                          [
                            _c(
                              "van-button",
                              {
                                attrs: {
                                  color: "#1373CC",
                                  block: "",
                                  type: "primary"
                                },
                                on: { click: _vm.handleSave }
                              },
                              [_vm._v(" 保存 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "van-col",
                          { attrs: { span: "12" } },
                          [
                            _c(
                              "van-button",
                              {
                                attrs: {
                                  color: "#1373CC",
                                  block: "",
                                  type: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.back()
                                  }
                                }
                              },
                              [_vm._v(" 返回 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.dialog.selectDialog,
                callback: function($$v) {
                  _vm.$set(_vm.dialog, "selectDialog", $$v)
                },
                expression: "dialog.selectDialog"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  columns: _vm.columns,
                  "value-key": _vm.valueKey
                },
                on: {
                  cancel: function($event) {
                    _vm.dialog.selectDialog = false
                  },
                  confirm: _vm.confirmPicker
                }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }