<template>
  <van-tabs v-if="utils.checkSystemType(constants.systemType.gzbSystem)" v-model="active">
    <van-tab :title="this.$route.query.put === 'put' ? '添加开票结果' : '发票记录'">
      <div class="partition-form">
        <van-form ref="appForm">
          <van-field label="发票种类：" placeholder="请输入发票种类" :value="utils.statusFormat(appForm.distinguish,'Distinguish')"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealTypeList')"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="关联订单名称：" :value="utils.isEffectiveCommon(appForm.orderId)" />
          <van-field v-else v-model="appForm.orderId" label="关联订单名称：" placeholder="请输入关联订单名称"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealList')"
          />
          <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票方类型：" :value="utils.statusFormat(appForm.invoiceSendType, 'EnterpriseOrgType')" />
          <van-field v-else :value="utils.statusFormat(appForm.invoiceSendType, 'EnterpriseOrgType')" label="开票方类型：" placeholder="请输入开票方类型" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendType')"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票方：" :value="utils.isEffectiveCommon(appForm.invoiceSendName)" />
          <van-field v-else-if="appForm.invoiceSendType == 7" v-model="appForm.invoiceSendName" label="开票方：" placeholder="请输入开票方" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-field v-else label="开票方：" placeholder="请输入开票方" :value="appForm.invoiceSendName" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendId')"
          />
          <van-field :value="utils.statusFormat(appForm.invoiceReceiveType, 'EnterpriseOrgType')" label="收票方类型：" placeholder="请输入收票方类型" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveType')"
          />
          <van-field v-if="this.$route.query.put === 'put'" v-model="appForm.invoiceReceiveName" label="收票方：" placeholder="请输入收票方" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-field v-else-if="appForm.invoiceReceiveType == 7" v-model="appForm.invoiceReceiveName" label="收票方：" placeholder="请输入开票方" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-field v-else label="收票方：" placeholder="请输入收票方" :value="appForm.invoiceReceiveName" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveId')"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="发票类型：" :value="utils.statusFormat(appForm.invoiceType, 'invoiceTypes')" />
          <van-field v-else v-model="appForm.invoiceTypeLabel" label="发票类型：" placeholder="请输入发票类型" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceTypes')"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票品名：" :value="utils.isEffectiveCommon(appForm.productName)" />
          <van-field v-else v-model="appForm.productName" label="开票品名：" placeholder="请输入开票品名" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票数量：" :value="utils.isEffectiveCommon(appForm.invoiceMeal)" />
          <van-field v-else v-model="appForm.invoiceMeal" label="开票数量：" placeholder="请输入开票数量" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票张数：" :value="utils.isEffectiveCommon(appForm.invoiceNum)" />
          <van-field v-else v-model="appForm.invoiceNum" label="开票张数：" placeholder="请输入开票张数" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票金额(合计: 元)：" :value="utils.isEffectiveCommon(appForm.invoiceMoney)" />
          <van-field v-else v-model="appForm.invoiceMoney" label="开票金额(合计: 元)：" placeholder="请输入开票金额" required :rules="[{ required: true }]"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-cell v-if="this.$route.query.put === 'put'" title="开票时间：" :value="utils.isEffectiveCommon(appForm.invoiceDate)" />
          <van-field v-else v-model="appForm.invoiceDate" label="开票时间：" placeholder="请输入开票时间" required :rules="[{ required: true }]"
                     :label-width="constants.labelWidth" :input-align="constants.input_align" @click="dialog.showDate = true"
          />
          <van-calendar v-model="dialog.showDate" :min-date="minDate" :max-date="maxDate" @confirm="confirmDate " />
          <van-cell v-if="this.$route.query.put === 'put'" title="备注：" :value="utils.isEffectiveCommon(appForm.remark)" />
          <van-field v-else v-model="appForm.remark" label="备注：" placeholder="请输入备注"
                     :input-align="constants.input_align" :label-width="constants.labelWidth"
          />
          <van-cell title="发票原件：">
            <upload-file rectangle-style upload-text="发票原件" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('fileDetailInfoVos', response, file, fileList)" @change="(arr) => change('fileDetailInfoVos', arr)" />
          </van-cell>
          <van-form ref="auditForm">
            <div class="fixed-btn-bottom" style="z-index: 2">
              <van-row gutter="20">
                <van-col span="12">
                  <van-button color="#1373CC" block type="primary" @click="handleSave">
                    保存
                  </van-button>
                </van-col>
                <van-col span="12">
                  <van-button color="#1373CC" block type="primary" @click="$router.back()">
                    返回
                  </van-button>
                </van-col>
              </van-row>
            </div>
          </van-form>
        </van-form>
        <!--下拉弹层-->
        <van-popup v-model="dialog.selectDialog" round position="bottom">
          <van-picker show-toolbar :columns="columns" :value-key="valueKey" @cancel="dialog.selectDialog = false"
                      @confirm="confirmPicker"
          />
        </van-popup>
      </div>
      <van-tab v-if="utils.checkSystemType(constants.systemType.gzbSystem)" title="审批意见">
        <workflow-audit :task-id="$route.query.taskId" page-key="ht_con_seal" :business-key="appForm.sealId" :proc-inst-id="$route.query.procInstId" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tab>
  </van-tabs>
  <div v-else class="partition-form">
    <div class="area-title">
      <p class="title">
        发票记录
      </p>
    </div>
    <van-form ref="appForm">
      <van-field label="发票种类：" placeholder="请输入发票种类" :value="utils.statusFormat(appForm.distinguish,'Distinguish')" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealTypeList')"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="关联订单名称：" :value="utils.isEffectiveCommon(appForm.orderId)" />
      <van-field v-else v-model="appForm.orderId" label="关联订单名称：" placeholder="请输入关联订单名称"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealList')"
      />
      <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票方类型：" :value="utils.statusFormat(appForm.invoiceSendType, 'EnterpriseOrgType')" />
      <van-field v-else :value="utils.statusFormat(appForm.invoiceSendType, 'EnterpriseOrgType')" label="开票方类型：" placeholder="请输入开票方类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendType')"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票方：" :value="utils.isEffectiveCommon(appForm.invoiceSendName)" />
      <van-field v-else-if="appForm.invoiceSendType == 7" v-model="appForm.invoiceSendName" label="开票方：" placeholder="请输入开票方" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-else label="开票方：" placeholder="请输入开票方" :value="appForm.invoiceSendName" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendId')"
      />
      <van-field :value="utils.statusFormat(appForm.invoiceReceiveType, 'EnterpriseOrgType')" label="收票方类型：" placeholder="请输入收票方类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveType')"
      />
      <van-field v-if="this.$route.query.put === 'put'" v-model="appForm.invoiceReceiveName" label="收票方：" placeholder="请输入收票方" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-else-if="appForm.invoiceReceiveType == 7" v-model="appForm.invoiceReceiveName" label="收票方：" placeholder="请输入开票方" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-else label="收票方：" placeholder="请输入收票方" :value="appForm.invoiceReceiveName" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveId')"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="发票类型：" :value="utils.statusFormat(appForm.invoiceType, 'invoiceTypes')" />
      <van-field v-else v-model="appForm.invoiceTypeLabel" label="发票类型：" placeholder="请输入发票类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceTypes')"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票品名：" :value="utils.isEffectiveCommon(appForm.productName)" />
      <van-field v-else v-model="appForm.productName" label="开票品名：" placeholder="请输入开票品名" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票数量：" :value="utils.isEffectiveCommon(appForm.invoiceMeal)" />
      <van-field v-else v-model="appForm.invoiceMeal" label="开票数量：" placeholder="请输入开票数量" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票张数：" :value="utils.isEffectiveCommon(appForm.invoiceNum)" />
      <van-field v-else v-model="appForm.invoiceNum" label="开票张数：" placeholder="请输入开票张数" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票金额(合计: 元)：" :value="utils.isEffectiveCommon(appForm.invoiceMoney)" />
      <van-field v-else v-model="appForm.invoiceMoney" label="开票金额(合计: 元)：" placeholder="请输入开票金额" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell v-if="this.$route.query.put === 'put'" title="开票时间：" :value="utils.isEffectiveCommon(appForm.invoiceDate)" />
      <van-field v-else v-model="appForm.invoiceDate" label="开票时间：" placeholder="请输入开票时间" required :rules="[{ required: true }]"
                 :label-width="constants.labelWidth" :input-align="constants.input_align" @click="dialog.showDate = true"
      />
      <van-calendar v-model="dialog.showDate" :min-date="minDate" :max-date="maxDate" @confirm="confirmDate " />
      <van-cell v-if="this.$route.query.put === 'put'" title="备注：" :value="utils.isEffectiveCommon(appForm.remark)" />
      <van-field v-else v-model="appForm.remark" label="备注：" placeholder="请输入备注"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell title="发票原件：">
        <upload-file rectangle-style upload-text="发票原件" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('fileDetailInfoVos', response, file, fileList)" @change="(arr) => change('fileDetailInfoVos', arr)" />
      </van-cell>
      <van-form ref="auditForm">
        <div class="fixed-btn-bottom" style="z-index: 2">
          <van-row gutter="20">
            <van-col span="12">
              <van-button color="#1373CC" block type="primary" @click="handleSave">
                保存
              </van-button>
            </van-col>
            <van-col span="12">
              <van-button color="#1373CC" block type="primary" @click="$router.back()">
                返回
              </van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-form>
    <!--下拉弹层-->
    <van-popup v-model="dialog.selectDialog" round position="bottom">
      <van-picker show-toolbar :columns="columns" :value-key="valueKey" @cancel="dialog.selectDialog = false"
                  @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio, Tab, Tabs } from 'vant'
import moment from 'moment'
import UploadFile from '@/components/upload-file'
import WorkflowAudit from '@/components/workflow-audit'
export default {
  components: {
    WorkflowAudit,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    UploadFile
  },
  data () {
    return {
      appForm: {
        distinguish: '',
        orderId: '',
        orderNo: '',
        invoiceSendType: '',
        invoiceSendTypeLabel: '',
        invoiceNum: '',
        invoiceSendId: '',
        invoiceReceiveId: '',
        invoiceType: '',
        invoiceTypeLabel: '',
        productName: '',
        invoiceMeal: '',
        invoiceMoney: '',
        invoiceDate: '',
        remark: '',
        invoiceReceiveType: '',
        invoiceReceiveTypeLabel: '',
        fileDetailInfoVos: [],
        invoiceSendTyp: '',
        invoiceSendName: '',
        invoiceReceiveName: ''
      },
      active: 0,
      files: [],
      columns: [],
      valueKey: '',
      listType: '',
      minDate: new Date(moment().subtract(10, 'year')),
      maxDate: new Date(moment().add(10, 'year')),
      dialog: {
        selectDialog: false, // 控制下拉弹层
        goodsDialog: false,
        showDate: false
      },
      query: {},
      platformOrgName: [],
      companyList: [],
      orderList: [],
      invoiceTypeList: []
    }
  },
  created () {
    this.orderLists()
    if (this.$route.query.id) {
      this.getApplyDetail(this.$route.query.id)
    }
  },
  methods: {
    // 上传大小
    beforeUpload (file) {
      const isLt = file.size / 1024 / 1024 < 10
      if (!isLt) {
        Toast.fail('上传文件不能大于10M')
        return isLt
      } else {
        return isLt
      }
    },
    // 上传成功
    fileUploadSuccess (type, response, file, fileList) {
      this.appForm[type] = []
      if (!this._.isEmpty(fileList)) {
        fileList.forEach((item, index) => {
          if (item.response) {
            this.appForm[type].push(item.response)
          } else if (item.fileId) {
            this.appForm[type].push({ item })
          }
        })
      } else {
        this.appForm[type] = []
      }
    },
    change (type, arr) {
      this.appForm[type] = arr
    },
    fieldClick (list) {
      this.listType = list
      this.dialog.selectDialog = true
      if (list === 'invoiceSendType' || list === 'invoiceReceiveType') {
        this.columns = this.constants.EnterpriseOrgType
      } else if (list === 'invoiceTypes') {
        this.columns = this.invoiceTypeList
      } else if (list === 'invoiceSendId') {
        this.columns = this.platformOrgName
      } else if (list === 'invoiceReceiveId') {
        this.columns = this.companyList
      } else if (list === 'sealList') {
        this.columns = this.orderList
      } else if (list === 'sealTypeList') {
        this.columns = this.constants.Distinguish
      }
      const codeMapName = {
        invoiceSendType: 'label',
        invoiceReceiveType: 'label',
        invoiceTypes: 'dctValNm',
        invoiceSendId: 'orgName',
        invoiceReceiveId: 'orgName',
        sealList: 'orderName',
        sealTypeList: 'label'
      }
      this.valueKey = codeMapName[list]
    },
    // 下拉弹层确定
    confirmPicker (value) {
      this.dialog.selectDialog = false
      if (this.listType === 'invoiceSendType') {
        this.appForm.invoiceSendType = value.value
        this.appForm.invoiceSendTypeLabel = value.label
        const ajax = this.appForm.invoiceSendType === '1' ? this.api.contract.invoice.companyList : this.api.contract.invoice.otherCompanyList
        ajax(this.appForm.invoiceSendType).then(result => {
          this.platformOrgName = result.data.value || []
          this.appForm.invoiceSendName = ''
        })
      } else if (this.listType === 'invoiceReceiveType') {
        this.appForm.invoiceReceiveType = value.value
        this.appForm.invoiceReceiveTypeLabel = value.label
        const ajaxs = this.appForm.invoiceReceiveType === '1' ? this.api.contract.invoice.companyList : this.api.contract.invoice.otherCompanyList
        ajaxs(this.appForm.invoiceReceiveType).then(result => {
          this.companyList = result.data.value || []
          this.appForm.invoiceReceiveName = ''
        })
      } else if (this.listType === 'invoiceReceiveType') {
        this.appForm.invoiceReceiveType = value.value
        this.appForm.invoiceReceiveTypeLabel = value.label
      } else if (this.listType === 'invoiceTypes') {
        this.appForm.invoiceType = value.dctVal
        this.appForm.invoiceTypeLabel = value.dctValNm
      } else if (this.listType === 'invoiceSendId') {
        this.appForm.invoiceSendId = value.enterpriseId
        this.appForm.invoiceSendName = value.orgName
      } else if (this.listType === 'invoiceReceiveId') {
        this.appForm.invoiceReceiveId = value.enterpriseId
        this.appForm.invoiceReceiveName = value.orgName
      } else if (this.listType === 'sealList') {
        this.appForm.orderId = value.orderId
        this.appForm.orderNo = value.orderNo
      } else if (this.listType === 'sealTypeList') {
        this.appForm.distinguish = value.value
        this.getExpenseTypeList()
      }
      this.showPicker = false
    },
    getExpenseTypeList () {
      const params = {
        dctTpCd: 'expenseType',
        filterTwo: this.appForm.distinguish
      }
      this.api.base.dataDict(params).then(res => {
        this.invoiceTypeList = res.data.value
      })
    },
    orderLists () {
      this.api.contract.invoice.orderList().then(res => {
        this.orderList = res.data.value
      })
    },
    // 计划采购日期确认
    confirmDate (date) {
      this.dialog.showDate = false
      this.appForm.invoiceDate = moment(date).format('YYYY-MM-DD')
    },
    // 详情
    getApplyDetail () {
      this.api.contract.invoice.detail(this.$route.query.id).then(res => {
        this.appForm = res.data.value
      })
    },
    // 贸易申请保存
    handleSave () {
      this.$refs.appForm.validate().then(() => {
        const ajax = this.$route.query.id ? this.api.contract.invoice.updateInvoice(this.appForm) : this.api.contract.invoice.addApply(this.appForm)
        ajax.then(res => {
          Toast(res.data.message || '保存成功')
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style lang="less">
.partition-form {
  margin-bottom:45px;
  .van-cell__title {
    color: #646566;
  }
}
</style>
